<template>
  <div class="container">
    <div class="banner" id="login-banner">
        <div class="w">
            <h3>客户中心</h3>
            <p>项目实施全程透明化跟踪,让客户随时能快速掌握整个项目实施过程,高效、实时、降低项目实施开发风险!</p>
        </div>
    </div>
    <div class="login w">
        <h3>客户中心/全程项目跟踪系统</h3>
        <div class="login_main w">
            <div v-show="errormessage" class="errormessage">帐号或密码错误,还可以尝试{{login_count}}次。<a href="javascript:void(0)" target="new" style="text-decoration:underline">在线联系客服..</a></div>
            <div><label>账户:</label><input type="text" name="uname" value="" placeholder="邮箱/手机号" />
            <div id="check_uid_param_msg" v-show="check_uid_param_msg" class="redtipLogin">请输入邮箱/手机号</div>
            </div>
            <div><label>密码:</label><input type="password" name="upassword" value="" placeholder="账户对应密码" />
            <div id="check_pwd_param_msg" v-show="check_pwd_param_msg" class="redtipLogin">请输入账户对应密码</div>
            </div>
            <div><button @click="login">登录</button></div>
        </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
    data: function(){
        return {
            check_uid_param_msg: false,
            check_pwd_param_msg: false,
            errormessage: false,
            login_count: 5
        };
    },
    methods:{
        login: function(){
            var __this = this;
            var uname = $('input[name="uname"]').val();
            var upassword = $('input[name="upassword"]').val();
            __this.check_uid_param_msg = false;
            if(uname == '')
            {
                __this.check_uid_param_msg = true;
                return ;
            }
            __this.check_pwd_param_msg = false;
            if(upassword == '')
            {
                __this.check_pwd_param_msg = true;
                return ;
            }
            __this.check_uid_param_msg = false;
            __this.check_pwd_param_msg = false;
            __this.errormessage = true;
            if(__this.login_count <= 0)
            {
                return ;
            }
            __this.login_count--;
        }
    }
}
</script>

<style>
.banner h3{ font-size: 25px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 14px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; outline: none; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
#login-banner{ height: 180px !important;}
.login{ min-height: 500px;}
.login h3{font-size: 25px; color: #000000; height: 36px; line-height: 36px; font-weight: 400; text-align: center; padding: 64px 0 12px; text-align: center;}
.login_main{ width: 432px; font-size: 12px;}
.login_main input{width: 375px; height: 46px; line-height: 30px; display: inline; padding-left: 16px; border: 1px #cccccc solid; outline: none; padding-right: 39px; border-radius: 4px; font-size: 14px; color: #000000;}
.login_main label{ font-size: 14px; line-height: 40px;}
.login_main button{text-align: center; line-height: 29px; height: 48px; width: 432px; cursor: pointer; border: 0 none; font-size: 16px; background: #004172; border-radius: 4px; font-size: 18px; color: #FFFFFF; margin-top: 11px;}
.login_main .redtipLogin{word-break: break-all; word-wrap: break-word; font-size: 12px; color: #FA2A2D; letter-spacing: 0; line-height: 14px; margin-top: 6px; text-align: left;}
.login_main .errormessage{ text-align: center; font-size: 12px; color: #FF0000; min-height: 14px; text-decoration: none; padding-top: 20px; padding-bottom: 6px; word-wrap: break-word;}
</style>
